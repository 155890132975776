.deskTopWrapper {
  background: url(../../../assets/images/free.png);
  background-size: cover;

  background-repeat: no-repeat;
  background-color: #000000;
  width: 100%;
}

.responsiveWrapper,
.tabletWrapper {
  display: none;
}

@media screen and (max-width: 560px) {
  .deskTopWrapper,
  .tabletWrapper {
    display: none;
  }

  .responsiveWrapper {
    background: url(../../../assets/images/free.png);
    background-size: cover;

    background-repeat: no-repeat;
    background-color: #000000;
    width: 100%;
  }
}

@media screen and (min-width: 668px) and (max-width: 800px) {
  .deskTopWrapper,
  .responsiveWrapper {
    display: none;
  }

  .tabletWrapper {
    background: url(../../../assets/images/free.png);
    background-size: cover;

    background-repeat: no-repeat;
    background-color: #000000;
    width: 100%;
  }
}
