.deskTopWrapper {
  background-color: #ffffff;
  width: 100%;
}

.grad {
  background: linear-gradient(0deg, #000000 -3.17%, rgba(0, 0, 0, 0) 49.77%);
}

.deskTopContent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.responsiveWrapper, .tabletWrapper {
  display: none;
}

.mobileBG {
  display: none;
  background-color: #ffffff;
}

@media screen and (max-width: 600px) {
  .deskTopWrapper, .tabletWrapper {
    display: none;
  }

  .responsiveWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
  }

  .mobileBG {
    display: flex;
  }
}

@media screen and (min-width: 668px) and (max-width: 1200px) {
  .deskTopWrapper, .responsiveWrapper {
    display: none;
  }

  .tabletWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
  }

  .mobileBG {
    display: flex;
  }
}
