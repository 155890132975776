.deskTopWrapper {
  background-color: #f6f6f3;
  width: 100%;
}

.grad {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.deskTopContent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.responsiveWrapper {
  display: none;
}

.mobileBG {
  display: none;
  background-color: #ffffff;
}

@media screen and (max-width: 860px) {
  .deskTopWrapper {
    display: none;
  }

  .responsiveWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f3;
  }

  .mobileBG {
    display: flex;
  }
}
