@import "~utils/vars";

@font-face {
  font-family: 'Tiempos Text Medium Italic';
  src: local('Tiempos Text Medium Italic'), url('../assets/fonts/tiempos.woff2') format('woff2');
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('../assets/fonts/manrope.woff2') format('woff2');
}

body,
html {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

img {
  user-select: none !important;
  --webkit-user-drag: none;
}

a {
  text-decoration: none;
  color: #555;
}

button {
  border: none;
  cursor: pointer;
}
h2 {
  color: #616161;
  font-weight: 500;
  font-size: 1.5rem;
}

.withPadding {
  padding-inline: $inlinePadding;
}
