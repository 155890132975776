@import "~utils/vars.scss";

.wrapper {
  min-height: 50vh;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center;
  padding: 4% 10%;
}
.card1 {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  max-width: 1000px;
  height: auto;
  background-size: cover;
  background-position: center;
  box-shadow: none !important;
  border-radius: 0px !important;
  gap: 50px;
}

.card2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: auto;
  padding-bottom: 85%;
  background-size: cover;
  background-position: center;
  box-shadow: none;
  border-radius: 0px !important;
  background-image: url("../../assets/images/keamNavigator.jpeg");
  margin-bottom: 40px;
}
@media screen and (max-width: 560px) {
  .card1 {
    gap: 20px;
  }
}
