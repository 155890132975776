.container {
  margin: 0 auto;
  // padding: 20px;
  font-family: Arial, sans-serif;
  padding: 1em;
  padding-inline: 4em;


  display: flex;

  .selectors {
    width: 30%;
  }

  h1 {
    text-align: center;
    color: #333;
  }

  .label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
  }

  .input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;

    &:hover {
      background-color: #218838;
    }
  }

  .colleges {
    padding-left: 4em;
    padding-top: 2em;
    width: 70%;

    h2 {
      text-align: center;
      color: #555;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 1em;

      li {
        background-color: #f8f9fa;
        margin: 5px 5px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: background-color 0.3s ease;
        // display: inline-block;

        &:hover {
          background-color: #e2e6ea;
        }

        .college-name {
          font-weight: bold;
        }

        .college-details {
          margin-top: 5px;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }
}
