.deskTopWrapper {
  background: url(../../../../assets/images/bannerIsro.png);
  background-size: cover;

  /* or 'contain' based on your preference */
  background-repeat: no-repeat;
  background-color: #ffffff;
  width: 100%;
  height: 58vh;
}

.deskTopContent {
  margin-top: 10px;
  margin-bottom: 10px;
}

.responsiveWrapper, .tabletWrapper {
  display: none;
}

.mobileBG {
  display: none;
  background-color: #ffffff;
}

@media screen and (max-width: 560px) {
  .deskTopWrapper, .tabletWrapper {
    display: none;
  }

  .responsiveWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: url(../../../../assets/images/isroMobile.png);
    background-size: cover;
    width: 100%;
    height: 700px;
  }

  .mobileBG {
    display: flex;
  }
}

@media screen and (min-width: 668px) and (max-width: 800px) {
  .deskTopWrapper, .responsiveWrapper {
    display: none;
  }

  .tabletWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../../../../assets/images/isroTab.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }

  .mobileBG {
    display: flex;
  }
}
