$navbarColor: white;

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 88px;
  background-color: $navbarColor;
  padding: 23px 50px 23px 50px;
  position: fixed;
  z-index: 100;
}
.navBarLogoSect {
  display: "flex";
  align-items: "center";
  width: "420px";
  justify-content: "space-between";
}
.visible {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.hidden {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.desktopMenu {
  display: flex;
  align-items: center;
  gap: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #040714 !important;
  font-family: "Manrope", sans-serif;
  white-space: nowrap;
}
.contactBtn {
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: #43ba80;
  font-family: "Manrope", sans-serif;

  border-radius: 23px;
  padding: 10px 24px 10px 24px;
}
.ham {
  color: black !important;
}
.navlink {
  color: black;
  transition: all 0.2s;
  &:hover {
    font-weight: 500;
  }
}
.menuList.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 5em;
  left: 0;
  width: 100%; /* This ensures the menu spans across the screen */
  background-color: $navbarColor;
  height: fit-content;
  z-index: 100000;

  /* To hide by default */
  padding: 0;
  height: 0;
  overflow-y: hidden;

  transition: all 0.1s;

  .navlink {
    display: inline-block;
    padding-block: 1em;
    width: 10em;
    text-align: center;
  }

  .deskTopActive {
    padding-bottom: 3px;
    border-bottom: 1px solid #43ba80;
  }

  .mobActive {
    color: #43ba80;
  }

  &.open {
    padding-block: 1em;
    height: 28em;
    overflow-y: hidden;
  }
  .contactBtn {
    font-size: 15px;
    font-weight: 600;
    color: #43ba80 !important;
    background-color: transparent;
    border: 1px solid #43ba80;
    border-radius: 23px;
    padding: 10px 24px 10px 24px;
  }
}
@media screen and (max-width: 1300px) {
  nav {
    padding-top: 0;
    padding-bottom: 0;

    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (max-width: 1100px) {
  nav {
    border-radius: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .logo {
    width: 70% !important;
  }
}
